import React from "react";

function PrivacyNotice() {
    return (
      <>
      <div className="conditions">
        <div className="fz-32 align-center mb-32 bold">Privacy Notice</div>
        <div className='align-left'>
        <p className='bold white green-background'>INFORMATION ABOUT US</p>
<p>Data Reply, a trading name of Reply Ltd (collectively referred to in this privacy notice as We, Us, and Our) takes its data protection and privacy responsibilities seriously. This privacy notice (Notice) explains how We collect, use and share personal information in connection with your use of the website https://weheroesportal.com/ (the Site), including: </p>

<ul>
<li>What personal information We collect and when and why We use it;</li>
<li>How We share personal information;</li>
<li>Explaining more about direct marketing;</li>
<li>How We protect and store personal information;</li>
<li>Legal rights available to help manage your privacy; and</li>
<li>How you can contact Us for more support.</li>
</ul>
<p>We may amend this Notice from time to time to keep it up to date with legal requirements and the way We operate Our business. Please regularly check these pages for the latest version of this Notice. </p>
<p>You might find external links to third party websites on or through Our Site, and this Notice describes how We will share your personal information with selected third party partners (Partners) for the purpose of verifying your eligibility for offers or discounts for products and/or services which may be of interest to you. When Our Partners use your personal information for this purpose, or otherwise to provide you with offers, discounts or other information about their products and services they will act as data controllers of your personal information, which means that they are in charge of how they handle your information and that We are not responsible for this.</p>
<p>This Notice does not apply to your use of a third party site or the use of your personal information by Our Partners with whom We share your personal information. We encourage you to carefully read and review the privacy policy of each of the Partners We share your information with (please see the How We share personal information section below for more details).</p>

<p className='bold white green-background'> WHAT PERSONAL INFORMATION WE COLLECT AND WHEN AND WHY WE USE IT</p>
<p className='bold green'>When We collect information </p>
<p>We collect information about you when you register with Us to use Our Site.</p>
<p className='bold green'>Personal information We collect and use </p>
<p>We collect certain limited information about you when you register with Us to use Our Site. We will only ask you for the minimum amount of personal information We require to allow Us to verify your eligibility for the Site and administer your account, and to allow Our Partners to provide relevant discounts and offers.</p>
<p>We will collect the following personal information from you:</p>
<ul>
<li>Your full name</li>
<li>Your home address (including your postcode)</li>
<li>Your work email address</li>
<li>Your account password</li>
</ul>

<p>We request that you ensure all information you provide to Us is accurate. If you become aware that any of the information is no longer accurate or if your circumstances change, you should contact Us.</p>
<p>If you take advantage of offers or discounts available from Our Partners using a unique identification code you obtain through the Site, We will also obtain confirmation from the relevant Partners of the time/date that the identification stamp was used. </p>
<p className='bold green'>Why We use your personal information, and the legal basis for using your personal information</p>
<p>Once you have provided Us with the information referred to above, We will use that information for the following purposes (as appropriate):</p>

<table>
  <tr>
    <th>How We use your information</th>
    <th>What is the legal basis for Our use of your information</th>
  </tr>
  <tr>
    <td>Verifying your identity, managing, running and administering your account or use of the Site.</td>
    <td>Our use of your personal information is necessary to perform a contract with you.</td>
  </tr>
  <tr>
    <td>Passing it to Our Partners for the purpose of verifying your eligibility for offers or discounts for products and/or services which may be of interest to you. When Our Partners use your personal information for this purpose, or otherwise to provide you with offers, discounts or other information about their products and services they will act as data controllers of your personal information, which means that they are in charge of how they handle your information and We are not responsible for this.	</td>
    <td>Our use of your personal information is necessary for the legitimate interests pursued by Data Reply.</td>
  </tr>
  <tr>
      <td>To send you communications relating to your use of the Site, for example where We need to notify you of changes to the Site, the terms and conditions, or this Notice.</td>
      <td>Our use of your personal information is necessary for the legitimate interests pursued by Data Reply.</td>
  </tr>
  <tr>
      <td>To send you periodic communications from Us (this may include communications by post, e-mail, telephone, text message, picture messaging, fax and SMS), about features, products and services, events and special offers. Such communications from Us may include advertising We send out on behalf of third party companies or organisations. 	</td>
      <td>We will use your information in this way where you have provided your consent to Us using the personal information. We will at all times respect your preferences in relation to any direct marketing activity which We undertake.</td>
  </tr>
  <tr>
      <td>To send you communications relating to your use of the Site, for example where We need to notify you of changes to the Site, the terms and conditions, or this Notice.</td>
      <td>Our use of your personal information is necessary for the legitimate interests pursued by Data Reply.</td>
  </tr>
  <tr>
      <td>Market research, including statistical analysis of user behaviour which We may disclose to third parties in depersonalised, aggregated form.</td>
      <td>Our use of your personal information is necessary for the legitimate interests pursued by Data Reply.</td>
  </tr>
  <tr>
      <td>To enable Us to monitor and improve the performance of the Site.</td>
      <td>Our use of your personal information is necessary for the legitimate interests pursued by Data Reply.</td>
  </tr>
  <tr>
      <td>In order to enable Us to comply with any legal or regulatory requirements.</td>
      <td>Our use of your personal information is necessary to comply with a relevant legal or regulatory obligation that We have.</td>
  </tr>
  <tr>
      <td>For Our business and accounting records.</td>
      <td>Our use of your personal information is necessary for the legitimate interests pursued by Data Reply.</td>
  </tr>
</table> 

<p className='bold white green-background'>HOW WE SHARE YOUR PERSONAL INFORMATION</p>
<p>When you use Our Site We may share your personal information with various of Our Partners, service providers, and Our regulators in the manner and for the purposes described below:</p>
<ul>
<li>with Our Partners, for the purpose of verifying your eligibility for offers or discounts for products and/or services which may be of interest to you, in which case Our Partners will be responsible for how they handle your information. You can see a full list of the Partners with whom your personal information may be shared on Our Site;</li>
<li>with third parties who help manage Our business and deliver services. These third parties have agreed to confidentiality restrictions and use any personal information We share with them or which they collect on Our behalf solely for the purpose of providing the contracted service to Us. These include IT service providers who help manage Our IT and back office systems; </li>
<li>with Our regulators, which may include the Information Commissioner, to comply with all applicable laws, regulations and rules, and requests of law enforcement, regulatory and other governmental agencies; and</li>
<li>We may share in aggregate, statistical form, non-personal information regarding the visitors to Our website, traffic patterns, and website usage with Our service providers and/or advertisers. </li>
</ul>
<p className='bold white green-background'>EXPLAINING MORE ABOUT DIRECT MARKETING </p>
<p className='bold green'>How We use personal information to keep you up to date with Our products and services</p>
<p>We may use personal information to let you know about Our products and services that We believe will be of interest to you. We may contact you by email, post, or telephone or through other communication channels that We think you may find helpful. In all cases, We will respect your preferences for how you would like Us to manage marketing activity with you.  </p>
<p className='bold green'>How you can manage your marketing preferences</p>
<p>To protect privacy rights and to ensure you have control over how We manage marketing with you:</p>
<ul>
<li>We will take steps to limit direct marketing to a reasonable and proportionate level and only send you communications which We believe may be of interest or relevance to you; </li>
<li>you can ask Us to stop direct marketing at any time - you can ask Us to stop sending email marketing, by following the 'unsubscribe' link you will find on all the email marketing messages We send you. Alternatively you can contact Us using the contact details at the end of this Notice. Please specify whether you would like Us to stop all forms of marketing or just a particular type.</li>
</ul>
<p>We recommend you routinely review the privacy notices and preference settings that are available to you on the websites of Our partners, any social media platforms, as well as your preferences within your Site account.</p>
<p className='bold white green-background'>HOW WE PROTECT AND STORE YOUR INFORMATION   </p>
<p className='bold green'>Security</p>
<p>We have implemented and maintain appropriate technical and organisational security measures, policies and procedures designed to reduce the risk of accidental destruction or loss, or the unauthorised disclosure or access to such information appropriate to the nature of the information concerned. Measures We take include placing confidentiality requirements on Our staff members and service providers, and destroying or permanently anonymising personal information if it is no longer needed for the purposes for which it was collected.</p> 
<p className='bold green'>Storing your personal information</p>
<p>We will store your personal information for as long as is reasonably necessary for the purposes for which it was collected, as explained in this Notice. In some circumstances We may store your personal information for longer periods of time, for instance where We are required to do so in accordance with legal, regulatory, tax, accounting requirements. In specific circumstances We may store your personal information for longer periods of time so that We have an accurate record of your dealings with Us in the event of any complaints or challenges, or if We reasonably believe there is a prospect of litigation relating to your personal information or dealings.</p>
<p className='bold white green-background'>LEGAL RIGHTS AVAILABLE TO HELP MANAGE YOUR PRIVACY</p>
<p>Subject to certain exemptions, and in some cases dependent upon the processing activity We are undertaking, you have certain rights in relation to your personal information, as described below:</p>
<p className='bold green'>Right to access personal information </p>
<p>You have a right to request that We provide you with a copy of your personal information that We hold and you have the right to be informed of: (a) the source of your personal information; (b) the purposes, legal basis and methods of processing; (c) the data controller’s identity; and (d) the entities or categories of entities to whom your personal information may be transferred.</p>
<p className='bold green'>Right to rectify or erase personal information </p>
<p>You have a right to request that We rectify inaccurate personal information. We may seek to verify the accuracy of the personal information before rectifying it. </p>
<p>You can also request that We erase your personal information in limited circumstances where:</p>
<ul>
<li>it is no longer needed for the purposes for which it was collected; or</li>
<li>you have withdrawn your consent (where the data processing was based on consent); or</li>
<li>following a successful right to object (see the Right to object to the processing of your personal information section below); or</li>
<li>it has been processed unlawfully; or</li>
<li>to comply with a legal obligation to which We are subject. </li>
</ul>
<p>We are not required to comply with your request to erase personal information if the processing of your personal information is necessary: </p>
<ul>
    <li>for compliance with a legal obligation; or</li>
    <li>for the establishment, exercise or defence of legal claims.</li>
</ul>	
<p className='bold green'>Right to restrict the processing of your personal information </p>
<p>You can ask Us to restrict your personal information, but only where:</p>
<ul>
    <li>its accuracy is contested, to allow Us to verify its accuracy; or</li>
    <li>the processing is unlawful, but you do not want it erased; or</li>
    <li>it is no longer needed for the purposes for which it was collected, but We still need it to establish, exercise or defend legal claims; or</li>
    <li>you have exercised the right to object, and verification of overriding grounds is pending.</li>
</ul>
<p>We can continue to use your personal information following a request for restriction where:</p>
<ul>
    <li>We have your consent; or</li>
    <li>to establish, exercise or defend legal claims; or</li>
    <li>to protect the rights of another natural or legal person. </li>
</ul>
<p className='bold green'>Right to transfer your personal information</p>
<p>You can ask Us to provide your personal information to you in a structured, commonly used, machine-readable format, or you can ask to have it transferred directly to another data controller, but in each case only where:</p>
<ul>
    <li>the processing is based on your consent or on the performance of a contract with you; and</li>
    <li>the processing is carried out by automated means. </li>
</ul>
<p className='bold green'>Right to object to the processing of your personal information</p>
<p>You can object to any processing of your personal information which has Our legitimate interests as its legal basis, if you believe your fundamental rights and freedoms outweigh Our legitimate interests. </p>
<p>If you raise an objection, We have an opportunity to demonstrate that We have compelling legitimate interests which override your rights and freedoms. </p>
<p className='bold green'>Right to object to how We use your personal information for direct marketing purposes</p>
<p>You can request that We change the manner in which We contact you for marketing purposes.</p>
<p className='bold green'>Right to lodge a complaint with your local supervisory authority</p>
<p>You have a right to lodge a complaint with the Information Commissioner (www.ico.org.uk or by phone at 0303 123 1113) or if you have concerns about how We are processing your personal information. </p>
<p>We ask that you please attempt to resolve any issues with Us first, although you have a right to contact the Information Commissioner at any time.</p>
<p>We may ask you for additional information to confirm your identity and for security purposes, before disclosing the personal information requested to you in response to any request raised by you. We reserve the right to charge a fee where permitted by law, for instance if your request is manifestly unfounded or excessive.</p>
<p>You can exercise your rights by contacting Us. Subject to legal and other permissible considerations, We will make every reasonable effort to honour your request promptly or inform you if We require further information in order to fulfil your request.  </p>
<p>We may not always be able to fully address your request, for example if it would impact the duty of confidentiality We owe to others, or if We are legally entitled to deal with the request in a different way. </p>
<p className='bold white green-background'>CONTACT US</p>
<p>The primary point of contact for all issues arising from this Notice, is Our Data Protection Officer. The Data Protection Officer can be contacted in the following ways:</p>
<p><span className='bold green'>Email:</span> <a href="mailto:info.data.uk@reply.com">info.data.uk@reply.com</a></p>
<p><span className='bold green'>Postal Address:</span> Nova South, 160 Victoria Street, Westminster, London SW1E 5LB </p>
<p>If you have any questions, concerns or complaints regarding Our compliance with this Notice and the data protection laws, or if you wish to exercise your rights, We encourage you to first contact Us. We will investigate and attempt to resolve complaints and disputes and will make every reasonable effort to honour your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by data protection laws.</p>
        </div>
      </div>
      </>
    )
  }

  export default PrivacyNotice;