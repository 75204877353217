import React from "react";

function TnC() {
    return (
      <>
      <div className="conditions">
        <div className="fz-32 align-center mb-32 bold">Terms and conditions</div>
        <div className='align-left'>
        <p className='bold white green-background'>INFORMATION ABOUT US AND OUR TERMS</p>
<p>These terms and conditions (<span className='green bold'>Terms</span>) apply to the website weheroesportal.com (the <span className='green bold'>Site</span>), the use of the Site and access to the services provided through the Site.</p>
<p>The Site is exclusively intended for use by certain types of key workers, as identified on the Site’s home page from time to time, who have registered with the Site (<span className='green bold'>You</span> and <span className='green bold'>Your</span>) . Registration will provide access to such promotions and/or other discount offers (<span className='green bold'>Offers</span>) which may be made available from time to time by our third party partners (<span className='green bold'>Partners</span>). </p>
<p>Please read these Terms carefully and print a copy for Your future reference. </p>
<p>By registering with the Site You agree that You have read, understood and accepted these Terms and the <span className='green bold'>Privacy Notice</span> (each as amended from time to time – please see section <span className='green bold'>We may make changes to these Terms</span> for further information about this). </p>
<p>If You do not agree to these Terms, You must not use the Site. </p>
<p className='bold white green-background'>1.	WHO WE ARE </p>
<p>1.1	weheroesportal.com is a Site operated by <span className='green bold'></span>Data Reply, a trading name of <span className='green bold'>Reply Ltd</span> (collectively referred to in these Terms as <span className='green bold'>We</span>, <span className='green bold'>Us</span> and <span className='green bold'>Our</span>). We are registered in England and Wales under company number 3847202 and have Our registered office at 38 Grosvenor Gardens, Westminster, London, SW1W0EB. Our main trading address is Nova South, 160 Victoria Street, Westminster, London SW1E 5LB. Our VAT number is 742468814.</p>
<p>1.2	We are a limited company.</p>
<p className='bold white green-background'>2.	HOW TO CONTACT US</p>
<p>2.1	To contact Us, please email <a href="mailto:info.data.uk@reply.com">info.data.uk@reply.com</a></p>
<p>2.2	If You would like to make a complaint or feel that any materials appearing on the Site are offensive, objectionable or potentially defamatory please contact Us by email at info.data.uk@reply.com and provide full details of the nature of Your complaint and the materials to which the complaint relates.</p>
<p className='bold white green-background'>3.	REGISTRATION AND USE OF THE SITE </p>
<p>3.1	You will be required to register Your name, address, and email address. You may also choose to register Your telephone number. </p>
<p>3.2	We will then share Your personal information with Partners in order that they may verify Your identity when You seek to redeem a relevant Offer. To find out more please see section <span className='green bold'>How We use Your information</span> and the <span className='green bold'>Privacy Notice</span>.</p>
<p>3.3	Offers will be displayed on the Site. Please note that all Offers are made at the discretion of the Partners and we cannot guarantee the volume or availability of Offers.  </p>
<p>3.4	To use the Offers You will need to contact the relevant Partner directly. You are solely responsible for Your interactions with Partners and it is up to You to determine whether You wish to proceed with an Offer. All contracts for the supply of goods and/or services pursuant to Offers are made direct by You and the Partner and You shall be responsible for making all applicable payments to a Partner. We are not involved in this process and subject to 11.1, We have no responsibility or liability in respect of Your interaction with Partners. </p>
<p>3.5	You can update Your details or delete Your account by emailing info.data.uk@reply.com.  </p>
<p className='bold white green-background'>4.	YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</p>
<p>4.1	If You choose or You are provided with a user identification code, password or any other piece of information as part of Our security procedures, You must treat such information as confidential. You must not disclose it to any third party.</p>
<p>4.2	We have the right to disable any user identification code or password, whether chosen by You or allocated by Us, at any time, if in Our reasonable opinion You have failed to comply with any of these Terms. </p>
<p>4.3	If You know or suspect that anyone other than You knows Your user identification code or password, You must promptly notify Us at info.data.uk@reply.com. </p>
<p className='bold white green-background'>5.	USE OF THE SITE</p>
<p>5.1	You shall not allow any other person to access the Site using Your registration details. </p>
<p>5.2	Please note that We only provide the Site for domestic and private use. You agree not to use the Site for any commercial or business purposes.</p>
<p>5.3	You may print off one copy, and may download extracts, of any page(s) from the Site for Your personal use.</p>
<p>5.4	You must not modify the paper or digital copies of any materials You have printed off or downloaded in any way, and You must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
<p>5.5	Our status (and that of any identified contributors) as the authors of content on the Site must always be acknowledged.</p>
<p>5.6	You must not misuse the Site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to the Site, the server on which the Site is stored or any server, computer or database connected to the Site. You must not use manual or automated software, devices, scripts, robots, or other means or processes to access, "scrape", "crawl", or "spider" any pages contained in the Site.</p>
<p className='bold white green-background'>6.	OUR INTELLECTUAL PROPERTY  </p>
<p>6.1	Other than in relation to links to Offers or Partner or third party websites, We or Our licensors are the owner or the licensee of all intellectual property rights in the Site and in the material published on it, including all brand names and trade marks. All such rights are reserved and nothing in these Terms will be interpreted as giving You ownership or rights in such intellectual property or the data contained therein.</p>
<p>6.2	You are not permitted to use Our intellectual property without Our approval, unless expressly permitted under section <span className='green bold'>Use of the Site.</span> </p>
<p className='bold white green-background'>7.	HOW WE MAY USE YOUR PERSONAL INFORMATION</p>
<p>7.1	To find out how We use Your details or if You wish to access the personal information that We are processing about You or request its deletion, please refer to section <span className='green bold'>Registration and use of the Site</span> and Our <span className='green bold'>Privacy Notice</span>. </p>
<p>7.2	Please note We may share certain personal information with Our Partners (see section <span className='green bold'>Registration and use of the Site</span> and Our <span className='green bold'>Privacy Notice</span>), who may collect and treat information they collect differently, so We encourage You to carefully read and review the Privacy Notice and terms of use for each website You visit.</p>
<p className='bold white green-background'>8.	TERM AND TERMINATION</p>
<p>8.1	These Terms remain applicable while You are a registered user of the Site.</p>
<p>8.2	We may, at any time and for any reason, withdraw Your registration and Your right of access to the Site.</p>
<p>8.3	You may, at any time and for any reason withdraw Your registration and delete Your account by emailing info.data.uk@reply.com.</p>
<p>8.4	In the event of Your registration ceasing for any reason, You must not attempt to use the Site.</p>
<p>8.5	If for any reason these Terms come to an end, this section 8 (<span className='green bold'></span>Term and Termination) and sections 9 (<span className='green bold'>We are not responsible for websites We link to</span>), 10 (<span className='green bold'>Do not rely on the information on this Site</span>), 11 (<span className='green bold'>Our responsibility for loss or damaged suffered by You</span>), 12 (<span className='green bold'>Our rights if You breach these Terms</span>), 18 (<span className='green bold'>Giving up rights</span>), 19 (<span className='green bold'>No third party rights</span>) and 21 (<span className='green bold'>Which country's laws apply to any disputes?</span>) inclusive will continue to be enforceable, and shall be valid and enforceable against You.</p>
<p className='bold white green-background'>9.	WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</p>
<p>9.1	Where the Site contains links to websites and resources provided by Partners or other websites and resources provided by third parties, these links are provided for Your information only. Such links should not be interpreted as approval by Us of those linked websites or information You may obtain from them.</p>
<p>9.2	We have no control over the contents of those websites or resources and We accept no responsibility for the availability, suitability, reliability or content of such Partner or third party websites and do not necessarily endorse the views expressed within them. We will not be liable for any loss or damage that may arise from Your use of them.</p>
<p className='bold white green-background'>10.	DO NOT RELY ON INFORMATION ON THIS SITE</p>
<p>10.1	The content on the Site is provided for general information only. It is not intended to amount to advice on which You should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on the Site.</p>
<p>10.2	Although We make reasonable efforts to update the information on the Site, We make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up to date.</p>
<p className='bold white green-background'>11.	OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</p>
<p>11.1	We do not exclude or limit in any way Our liability to You where it would be unlawful to do so. This includes liability for death or personal injury caused by Our negligence or the negligence of Our employees, agents or subcontractors and any liability for fraud or fraudulent misrepresentation.</p>
<p>11.2	We provide You with access to the Site free of charge and, subject to 11.1, We shall not be liable in any way for any loss, injury or damage of whatever kind caused in whole or in part by:</p>
<p>11.2.1	use of the Site or the information provided therein;</p>
<p>11.2.2	any failure, delay, interruption or otherwise of the provision of the Site or the information provided therein; </p>
<p>11.2.3	any act or omission of a Partner; or</p> 
<p>11.2.4	Our failure to perform any of Our obligations under these Terms.</p>
<p>11.3	Further to 11.2, Offers are provided to You by Partners and We do not guarantee that the Offers are currently available. You are advised to check any discounts have been applied prior to committing to any purchase.</p>
<p>11.4	We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material due to Your use of the Site or to Your downloading of any content on it, or on any website linked to it or to any email sent by Us to You.</p>
<p>11.5	In no event, subject to 11.1, shall We be liable for any loss of profits, goodwill, savings, business or business interruption (whether any of the foregoing are direct or indirect), or for any special, indirect, incidental or consequential damages.</p>
<p>11.6	To the maximum extent permitted by law, We expressly exclude all representations, warranties, obligations and liabilities in connection with the Site, Offers, Partner websites, or any other third party website, and the information provided therein.</p>
<p className='bold white green-background'>12.	OUR RIGHTS IF YOU BREACH THESE TERMS</p>
<p>Should You make any illegal and/or authorised use of the Site, and/or fail to comply with these Terms We may take such action as We reasonably determine to be appropriate including amongst other measures temporarily or permanently removing Your registration and right to use the Site.</p>
<p className='bold white green-background'>13.	MONITORING YOUR USE OF THE SITE AND LEGAL DISCLOSURE</p>
<p>13.1	We may disclose such information to law enforcement authorities as We reasonably feel is necessary or as required by law.</p>
<p>13.2	You acknowledge that We have no obligation to monitor Your access to or use of the Site, but that We have the right to do so for the purpose of operating the Site, to ensure Your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. We reserve the right, at any time and without prior notice, to remove or disable access to any content that We, at Our sole discretion, consider to be in violation of these Terms or otherwise harmful to the Site provided.</p>
<p className='bold white green-background'>14.	WE MAY MAKE CHANGES TO THESE TERMS</p>
<p>We may amend these Terms from time to time. We will give You reasonable notice of any major changes to these Terms. If You are unhappy with the changes to the Terms You can withdraw from these Terms by deleting Your account (please see section <span className='bold green'>Term and Termination</span>). </p>
<p className='bold white green-background'>15.	WE MAY MAKE CHANGES TO THE SITE</p>
<p>We may update and change the Site from time to time. We will try to give You reasonable notice of any major changes.</p>
<p className='bold white green-background'>16.	WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE</p>
<p>We may transfer Our rights and obligations under these Terms to another organisation. We will always tell You in writing if this happens and We will ensure that the transfer will not affect Your rights under the Terms.</p>
<p className='bold white green-background'>17.	THE SITE IS ONLY FOR USERS IN THE UK</p>
<p>The Site is directed to people residing in the United Kingdom. We do not represent that content available on or through the Site is appropriate for use or available in other locations.</p>
<p className='bold white green-background'>18.	GIVING UP RIGHTS </p>
<p>Any delay or failure by Us in exercising, or any waiver by Us of, Our rights under or in connection with these Terms will not limit or restrict the future exercise or enforceability of those rights.</p>
<p className='bold white green-background'>19.	NO THIRD PARTY RIGHTS </p>
<p>Only You and Us are entitled to enforce these Terms. Nothing in these Terms shall give, directly or indirectly, any third party any enforceable benefit or any right of action against Us and such third parties shall not be entitled to enforce any term of these Terms against Us.</p>
<p className='bold white green-background'>20.	INVALID PARTS OF THE TERMS</p>
<p>If any provision (or part of any provision) of these Terms is, or becomes illegal, invalid or unenforceable in any respect it shall not affect or impair the legality, validity or enforceability of any other provision of these Terms.</p>
<p className='bold white green-background'>21.	WHICH COUNTRY'S LAWS APPLY TO ANY DISPUTES?</p>
<p>Please note that these Terms, their subject matter and their formation, are governed by English law. You and We both agree that the courts of England and Wales will have exclusive jurisdiction except that if You are a resident of Northern Ireland You may also bring proceedings in Northern Ireland, and if You are resident of Scotland, You may also bring proceedings in Scotland.</p>
        </div>
      </div>
      </>
    )
  }

  export default TnC;