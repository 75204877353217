import React from "react";
import superhero from "./superhero.png";
import homeicon from "./home.svg";
import "./App.css";
import {
  Route,
  Link,
  withRouter,
  useHistory,
  Redirect,
} from "react-router-dom";
import TnC from "./TandC.js";
import PrivacyNotice from "./Privacy.js";

function Home() {
  return (
    <div className="column">
      <div className="relative">
        <p className="align-left fz-32 bold">
          WELCOME TO THE HEROES VALIDATION PLATFORM!
        </p>
        <p className="align-left mb-24">
          It’s time to say thanks to our heroes.
        </p>
        <p className="align-left mb-24">
          Thank you for taking care of our community!
        </p>
        <p className="align-left line-height-24" style={{ maxWidth: "380px" }}>
          If you belong to one of the keyworker categories identified by the
          government, please provide your employment details to enjoy exclusive
          benefits provided by our partners.
        </p>
        <img src={superhero} className="superhero-image" alt="Superhero" />
      </div>
      <div className="button-home-container">
        <Link to={"/signup"}>
          <button className="button-signup mr-24">Get Started</button>
        </Link>
        <Link to={"/login"}>
          <button className="button-signup">Log In</button>
        </Link>
      </div>
    </div>
  );
}

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        password: {
          value: "",
        },
        username: {
          value: ''
        }
      },
      error:null
    }
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    });
  };

  submitForm(e) {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        username: this.state.formControls.username.value,
        password: this.state.formControls.password.value,
      }),
      redirect: "follow",
    };

    fetch(
      "https://api.weheroesportal.com/authentication/login",
      requestOptions
    )
      .then((response) => {
        if(!response.ok) throw new Error(response.status);
        else return response.text();}
        )
      .then((result) => {
        const data = JSON.parse(result);
        const authToken = data.token;
        window.localStorage.removeItem('token');
        window.localStorage.setItem('token',JSON.stringify(authToken));
        this.props.history.push('/user');})
      .catch(error => {
        this.setState({error:true});
        })
  }

  render() {
    return (
      <>
      <form onSubmit={this.submitForm.bind(this)}>
        <div className="field-group">
          <label>Username</label>
          <input
            type="email"
            name="username"
            value={this.state.username}
            onChange={this.changeHandler}
            placeholder="Username"
            required
          />
        </div>
        <div className="field-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.changeHandler}
            placeholder="Password"
            required
          />
          <Link to={"/forgotpassword"}>
            <p>I forgot my password</p>
          </Link>
        </div>
        <input type="submit" value="Submit" className="button-signup" />
      </form>
      {this.state.error && <p><strong>Your username or password is invalid. Please check these and try again. Your username is the email that you signed up with.</strong></p>}
      </>
    );        
  }
}

const LoginComponentWithRouter = withRouter(LoginComponent);

function Login() {
  return (
    <div className="column">
      <LoginComponentWithRouter />

      {/* <div id="extras" className="footer">
        <Link to="/">
          <img src={homeicon} className="App-logo" alt="Home" align="right" />
        </Link>
      </div> */}
    </div>
  );
}

class ForgotCodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        forgotten: {
          value: "",
        },
      },
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
      error:null,
    });
  };

  submitForm(e) {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        username: this.state.formControls.forgotten.value,
      }),
      redirect: "follow",
    };

    window.localStorage.setItem(
      "forgotemail",
      this.state.formControls.forgotten.value
    );

    fetch(
      "https://api.weheroesportal.com/authentication/forgotpassword",
      requestOptions
    )
      .then((response) => {response.text();})
      .then((result) => {
        this.props.history.push("/newpassword");
      })
      .catch((error) => {
        this.setState({error:true});
      });
  }

  render() {
    return (
      <div className="column">
        <p className="align-left fz-16">
          Please indicate your Hero Email Address. If the email address is
          valid, you will receive a code that will allow you to change your
          password
        </p>
        <form onSubmit={this.submitForm.bind(this)}>
          <input
            type="email"
            name="forgotten"
            value={this.state.forgotten}
            onChange={this.changeHandler}
            required
            className="Input-login-box"
            placeholder="Email Address"
          />
          <div className="mt-24 align-right">
            <input className="button-signup" type="submit" value="Send Code" />
          </div>
        </form>
      </div>
    );
  }
}

const ForgotCodeComponentWithRouter = withRouter(ForgotCodeComponent);

class NewPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        forgotten: {
          value: window.localStorage.getItem("forgotemail"),
        },
        password: {
          value: "",
        },
        code: {
          value: "",
        },
      },
      error:null,
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    });
  };

  submitForm(e) {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        username: this.state.formControls.forgotten.value,
        password: this.state.formControls.password.value,
        code: this.state.formControls.code.value,
      }),
      redirect: "follow",
    };

    fetch(
      "https://api.weheroesportal.com/authentication/confirmfp",
      requestOptions
    )
      .then((response) => {        
        if(!response.ok) throw new Error(response.status);
        else return response.text();})
      .then((result) => {
        this.props.history.push("/login");
      })
      .catch((error) => {
        this.setState({error:true})
      });
  }

  render() {
    return (
      <>
        <p className="align-left fz-16">
          Please check your email and insert in the textbox below the code
          contained in the email. If you don’t see the email, please check the
          spam folder.
        </p>
        <form onSubmit={this.submitForm.bind(this)}>
          <div className="field-group mt-24">
            <label>Code</label>
            <input
              type="text"
              name="code"
              validation={this.state.code}
              onChange={this.changeHandler}
              required
              className="Input-login-box"
              placeholder="Write your code..."
            />
          </div>
          <div className="field-group">
            <label>New password</label>
            <input
              type="password"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?])[A-Za-z\d@$!%*?]{8,}$"
              name="password"
              validation={this.state.password}
              onChange={this.changeHandler}
              required
              className="Input-login-box"
              placeholder="Write your new password..."
            />
            <div className="align-left mt-4 mb-8 fz-12">
              At least 8 characters and these must include at least one digit,
              one special character (@$!%*?), one uppercase letter, one lowercase letter
            </div>
          </div>

          <input
            type="submit"
            className="button-signup mt-24"
            value="Reset Password"
          />
        </form>
        {this.state.error && <p><strong>There was an issue with resetting your password! Please try waiting for a few minutes before entering your code again, or request a new code.</strong></p>}
      </>
    );
  }
}

const NewPasswordComponentWithRouter = withRouter(NewPasswordComponent);

function Forgotten() {
  return (
    <div>
      <ForgotCodeComponentWithRouter />
      {/* <div id="extras" className="footer">
        <Link to="/">
          <img src={homeicon} className="App-logo" alt="Home" align="right" />
        </Link>
      </div> */}
    </div>
  );
}

function NewPassword() {
  return (
    <div className="column">
      <NewPasswordComponentWithRouter />
      {/* <div id="extras" className="footer">
        <Link to="/">
          <img src={homeicon} className="App-logo" alt="Home" align="right" />
        </Link>
      </div> */}
    </div>
  );
}

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      error: null,
      token: {
        value: window.localStorage.getItem("token"),
      },
      redirect: false,
    };
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/login'/>
    }
  }

  componentDidMount() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", JSON.parse(this.state.token.value));

    var requestOptions = {
      method: "GET",
      mode: "cors",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api.weheroesportal.com/user/myprofile",
      requestOptions
    )
      .then((response) => {    
        if (!response.ok && response.status===401) {this.setState({redirect: true});}   
        else if(!response.ok) {throw new Error(response.status);}
        else return response.text();})
      .then((result) => {
        const data = JSON.parse(result);
        this.setState({ profile: data, error: false });
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  }

  render() {
    return (
      <>
        {this.renderRedirect()}
        {this.state.error && (
          <p className="fz-32">
            Something bad happened, please try again later!
          </p>
        )}
        {!this.state.error && !this.state.profile && <p>Loading</p>}
        {!this.state.error && this.state.profile && (
          <div>
            <p className="align-left bold fz-32">
              Welcome Back {this.state.profile.name}{" "}
              {this.state.profile.surname}!
            </p>
            <div className="hero-code">
              <p className="align-left medium">Your Hero ID Code is</p>
              <div className="chip bold">{this.state.profile.userId}</div>
            </div>

            <div className="align-left mt-24">
              <span className="medium">Email:</span> {this.state.profile.email}
            </div>
            <div className="align-left mt-24">
              <span className="medium">Phone Number: </span>
              {this.state.profile.phoneNumber}
            </div>
            <div className="align-left mt-24">
              <span className="medium">Address Line 1: </span>
              {this.state.profile.address.line2}
            </div>
            <div className="align-left mt-24">
              <span className="medium">Address Line 2: </span>
              {this.state.profile.address.line1}
            </div>
            <div className="align-left mt-24">
              <span className="medium">Town: </span>
              {this.state.profile.address.town}
            </div>
            <div className="align-left mt-24">
              <span className="medium">Post Code: </span>
              {this.state.profile.address.postCode}
            </div>
            <div className="align-left mt-24">
              If you wish to delete your account, please let us know by sending
              an email to <b>info.data.uk@reply.com</b>
            </div>
          </div>
        )}
      </>
    );
  }
}

function WelcomeBack() {
  let history=useHistory()
  return (
    <div className="column">
      <User />
      {/* <div id="extras" className="footer">
        <Link to="/">
          <img src={homeicon} className="App-logo" alt="Home" align="right" />
        </Link>
      </div> */}
      <button
        className="button-signup mt-48 secondary"
        onClick={() => {
          history.push("/");
          window.localStorage.removeItem("token");
        }}
      >
        Sign out
      </button>
    </div>
  );
}

class SignupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        email: {
          value: "",
        },
        telephone: {
          value: "",
        },
        password: {
          value: "",
        },
        postcode: {
          value: "",
        },
        firstname: {
          value: "",
        },
        surname: {
          value: "",
        },
        address1: {
          value: "",
        },
        address2: {
          value: "",
        },
        town: {
          value: "",
        },
      },
      error:null,
      emailerror:null,
      conditions: false,
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.name === 'conditions' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  submitForm(e) {
    e.preventDefault();

    var address = {
      line1: this.state.formControls.address2.value,
      line2: this.state.formControls.address1.value,
      town: this.state.formControls.town.value,
      postCode: this.state.formControls.postcode.value,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        email: this.state.formControls.email.value,
        name: this.state.formControls.firstname.value,
        surname: this.state.formControls.surname.value,
        address: address,
        password: this.state.formControls.password.value,
        phoneNumber: this.state.formControls.telephone.value,
        privacyAccepted: this.state.conditions,
      }),
      redirect: "follow",
    };


    window.localStorage.removeItem("email");
    window.localStorage.setItem("email", this.state.formControls.email.value);

    fetch(
      "https://api.weheroesportal.com/authentication/signup",
      requestOptions
    )
      .then((response) => {
        if(!response.ok && response.status===400) {this.setState({emailerror:true,});
        throw new Error(response.status);}
        else if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((result) => {
        this.props.history.push("/validate");
      })
      .catch((error) => {
        this.setState({error:true});
      });
  }

  render() {
    return (
      <>
      <form className="sign-up-form" onSubmit={this.submitForm.bind(this)}>
        <div className="field-group">
          <label>Name*</label>
          <input
            type="text"
            name="firstname"
            pattern="[a-zA-Z]*"
            value={this.state.firstname}
            onChange={this.changeHandler}
            placeholder="Write your firstname..."
            required
          />
        </div>
        <div className="field-group">
          <label>Surname*</label>
          <input
            type="text"
            name="surname"
            pattern="[a-zA-Z]*"
            value={this.state.surname}
            onChange={this.changeHandler}
            placeholder="Write your surname..."
            required
          />
        </div>
        <div className="field-group">
          <label>Address Line 1*</label>
          <input
            type="text"
            name="address1"
            value={this.state.address1}
            onChange={this.changeHandler}
            placeholder="Write your address..."
            required
          />
        </div>
        <div className="field-group">
          <label>Address Line 2 </label>
          <input
            type="text"
            name="address2"
            value={this.state.address2}
            onChange={this.changeHandler}
            placeholder="Write your address... (Optional)"
          />
        </div>
        <div className="field-group">
          <label>Town*</label>
          <input
            type="text"
            pattern="[a-zA-Z]*"
            name="town"
            value={this.state.town}
            onChange={this.changeHandler}
            placeholder="Write your town..."
            required
          />
        </div>
        <div className="field-group">
          <label>Postcode*</label>
          <input
            pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
            type="text"
            name="postcode"
            value={this.state.postcode}
            onChange={this.changeHandler}
            required
            placeholder="Write your postcode..."
          />
        </div>
        <div className="field-group">
          <label>Telephone Number</label>
          <input
            type="text"
            pattern="([+][0-9]{1,12})"
            name="telephone"
            value={this.state.telephone}
            onChange={this.changeHandler}
            placeholder="Write your telephone... (Optional)"
          />
        <div className="align-left mt-4 mb-8 fz-12">
            Please include the country code, for example beginning with +44 for a UK number
        </div>
        </div>
        <div className="field-group">
          <label>Email Address*</label>
          <input
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.changeHandler}
            placeholder="Write your email..."
            required
          />
        </div>
        <div className="field-group">
          <label>Password*</label>
          <input
            type="password"
            name="password"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?])[A-Za-z\d@$!%*?]{8,}$"
            value={this.state.password}
            onChange={this.changeHandler}
            required
            placeholder="Write your password..."
          />
          <div className="align-left mt-4 mb-8 fz-12">
            At least 8 characters and these must include at least one digit, one
            special character (@$!%*?), one uppercase letter, one lowercase letter
          </div>
        </div>
        <div className="field-group flex-flow-row">
          <input
            type="checkbox"
            name="conditions"
            onClick={this.changeHandler}
            checked={this.state.conditions}
            onChange={this.handleChange}
            required
          />
          <label>I agree to accept the <Link to={"/termsandconditions"} target="_blank">terms and conditions</Link> and have read the <Link to={"/privacynotice"} target="_blank">privacy notice</Link>*</label>
        </div>
        <input type="submit" value="Sign Up" className="button-signup" />
      </form>
      {this.state.emailerror && <p><strong>Please check your email address again. It may be invalid (please see the accepted domains above), or you may have signed up before. If you have signed up before, please <Link to={"/validate"}>confirm your email</Link> or <Link to={"/login"}>log in</Link>.</strong></p>}
      </>
    );
  }
}

const SignUpComponentWithRouter = withRouter(SignupComponent);

function SignUp() {
  return (
    <div className="column">
      <div className="fz-32 align-left mb-32 bold">Sign up</div>
      <p className="align-left bold">
        If you fall in one of these categories, please fill in the following
        details:
      </p>

      <p className="align-left ml-16">
        - Health and social care employees [email domains: nhs.net, nhs.uk,
        hscni.net]
      </p>
      <p className="align-left ml-16">
        - Public safety and national security employees [email domains:
        police.uk, gov.uk, mod.uk]
      </p>
      <p className="align-left ml-16">
        - Transport employees [email domains: tfl.gov.uk]
      </p>
      <SignUpComponentWithRouter />
      {/* <div id="extras" className="footer">
        <Link to="/">
          <img src={homeicon} className="App-logo" alt="Home" align="right" />
        </Link>
      </div> */}
    </div>
  );
}

class ValidateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        validation: {
          value: "",
        },
      },
      username: {
        value: window.localStorage.getItem("email"),
      },
      error:null,
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    });
  };

  submitForm(e) {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "nh8kdZi67G61ku0XNLtlo4ldsn6EjZLy34cR4j4m");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        username: this.state.username.value,
        code: this.state.formControls.validation.value,
      }),
      redirect: "follow",
    };

    fetch(
      "https://api.weheroesportal.com/authentication/confirm",
      requestOptions
    )
      .then((response) => {
        if(!response.ok && response.status===400) {this.setState({emailerror:true}); throw new Error(response.status);}
        else if(!response.ok) {this.setState({error:true}); throw new Error(response.status);}
        else return response.text();})
      .then((result) => {
        this.props.history.push("/login");
      })
      .catch((error) => {
      });
  }

  render() {
    return (
      <>
      <form onSubmit={this.submitForm.bind(this)}>
        <input
          type="text"
          name="validation"
          value={this.state.validation}
          onChange={this.changeHandler}
          required
          className="Input-login-box"
          placeholder="Write your code..."
        />
        <br />
        <input className="button-signup mt-24" type="submit" value="Confirm" />
      </form>
      {this.state.error && <p><strong>Something bad has happened! Please wait a few minutes before trying again.</strong></p>}
      {this.state.emailerror && <p><strong>There was an issue with the validation. Please check the code again, and wait for a few minutes before trying again.</strong></p>}
      </>
    );
  }
}

const ValidateComponentWithRouter = withRouter(ValidateComponent);

function Validation() {
  return (
    <div className="column">
      <p className="align-left line-height-24">
        Please check your email and insert the code contained in the email in
        the box below. If you don’t see the email, please check your spam
        folder.
      </p>
      <ValidateComponentWithRouter />
      <p className="bold mt-24">Hurry, the code expires in 24 hours!</p>
      <div className="line-height-24">
        If you do not confirm your account within 24 hours, you will need to do the
        sign-up procedure again.
      </div>
      {/* <div id="extras" className="footer">
        <Link to="/">
          <img src={homeicon} className="App-logo" alt="Home" align="right" />
        </Link>
      </div> */}
    </div>
  );
}

class App extends React.Component {
  render() {
    return (
      <div id="body" className="App">
        <header className="App-header">
          <Link to="/">
            <img src={homeicon} alt='Home' />
          </Link>
          <h1 className="app-title align-left">Heroes Validation Platform</h1>
        </header>
        <Route exact={true} path="/">
          <Home />
        </Route>
        <Route exact={true} path="/login">
          <Login />
        </Route>
        <Route exact={true} path="/signup">
          <SignUp />
        </Route>
        <Route exact={true} path="/termsandconditions">
          <TnC />
        </Route>
        <Route exact={true} path="/privacynotice">
          <PrivacyNotice />
        </Route>
        <Route exact={true} path="/forgotpassword">
          <Forgotten />
        </Route>
        <Route exact={true} path="/validate">
          <Validation />
        </Route>
        <Route exact={true} path="/newpassword">
          <NewPassword />
        </Route>
        <Route path="/user">
          <WelcomeBack />
        </Route>
      </div>
    );
  }
}

export default App;
